/*
 * @Description: 
 * @Version: 1.0
 * @Autor: in hengqi by mengze 
 * @Date: 2021-08-19 17:13:56
 * @LastEditors: in hengqi by mengze
 * @LastEditTime: 2021-08-24 15:24:11
 */

//所有的金额相加
export function addPriceFixed( ...prices ){
    let price = prices.reduce( (prev, last)=>{
                    return prev + transFormToNumber(last);
                }, 0 );
    return (Math.round(price* 100) / 100).toFixed(2);
}
 

//所有的金额相减
export function  minusPriceFixed( ...prices ){
    let price = prices.reduce( (prev, last)=>{
        return transFormToNumber(prev) - transFormToNumber(last);  
    })
    return (Math.round(price* 100)/100).toFixed(2);
}

//判断两个浮点数是否相等
export function floatNumberEqual( prev, last ){
    let prevNumber = Number(prev);
    let lastNumber = Number(last);
    if( isNaN(prevNumber) || isNaN(lastNumber) ){
        return false;
    }
    return Math.abs(prevNumber - lastNumber) < 0.00001
}


function transFormToNumber( lastPrice ){
    lastPrice = Number(lastPrice);
    return isNaN(lastPrice) ? 0 : lastPrice;
}